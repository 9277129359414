// nuxt-ui/Shared/composables/regions.ts

import type { Region } from "~/src/region/domain/models/Region";
import Regions from "~/src/region/datasources/regions.json";
import { useCompetitionStore } from "~/nuxt-ui/stores/competitionStore";

interface UseRegions {
  regionParam: string | undefined;
  isRegionHome: boolean;
  isAmericas: boolean;
  isSpain: boolean;
  isItaly: boolean;
  isWorldCup: boolean;
  regionTimeZone: string;
  findRegionInfoById: (regionCountryId: number, key: string) => any | undefined;
}

export function useRegions(): UseRegions {
  const route = useRoute();
  const competitionStore = useCompetitionStore();
  const _regions: Region[] = Regions as Region[];
  const regionParam = route.params.region;
  const currentRegions = _regions.find(region => region.slug === regionParam);

  const hasMoreParams = route.path.split("/").length > 3; //contextDomain/lang/region

  function findRegionInfoById(countryId: number, key: string): any | undefined {
    const region: Region | undefined = _regions.find(region => region.apiId === countryId);
    return !!region ? region[key] : undefined;
  }

  return {
    regionParam: typeof regionParam === "string" ? regionParam : undefined,
    isRegionHome: !hasMoreParams,
    isAmericas: regionParam ? regionParam === "americas" : competitionStore.region?.slug === "americas",
    isSpain: regionParam ? regionParam === "espana" : competitionStore.region?.slug === "espana",
    isItaly: regionParam ? regionParam === "italia" : competitionStore.region?.slug === "italia",
    isWorldCup: regionParam ? regionParam === "world-cup" : competitionStore.region?.slug === "world-cup",
    regionTimeZone: currentRegions?.timeZone || "UTC",
    findRegionInfoById,
  };
}
