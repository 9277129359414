// src/match/infrastructure/ui-services/matchUiService.ts

import type { MatchStatusDTO } from "~/src/match/infrastructure/DTO/MatchDTO";
import type { MatchStatusSimplified } from "~/src/match/domain/models/Match";
import type { Event } from "~/src/match/domain/models/Event";
import type { ParameterCode } from "~/src/stats/domain/models/Parameter";
import matchService, {
  $MATCH_DICE_PARAMS,
  $MATCH_TIMELINE_EVENT_PARAMS,
} from "~/src/match/domain/services/matchService";
import { useWebContext } from "~/nuxt-ui/Shared/composables/webContext";

interface MatchUiService {
  transformStatus: (status: MatchStatusDTO | null | undefined) => MatchStatusSimplified | null;
  formatEventMinute: (event: Event) => string;
  getMatchingCode: (parameterAppString: string) => ParameterCode | string | undefined;
  getMatchingCodeImage: (parameterAppString: string) => ParameterCode | string | undefined;
}

const service = (): MatchUiService => ({
  transformStatus,
  formatEventMinute,
  getMatchingCode,
  getMatchingCodeImage,
});

export default service;

function formatEventMinute(event: Event): string {
  if (!event) return "";
  const halfDuration = 20;
  const endHalfMinute = halfDuration * event.half;
  const minute = Math.ceil(event.matchSecond / 60);
  const extraMinute = minute - endHalfMinute;
  if (minute < endHalfMinute || extraMinute <= 0) {
    return `${minute}'`;
  } else {
    return `${endHalfMinute}+${extraMinute}'`;
  }
}

function transformStatus(status: MatchStatusDTO | null | undefined): MatchStatusSimplified | null {
  if (!status) return null;

  switch (status) {
    case "notStarted":
      return "scheduled";

    case "inPlay1H":
    case "inPlay2H":
    case "inPlay1EH":
    case "inPlay2EH":
    case "inPlayP":
    case "halfTime":
    case "breakTime":
      return "live";

    case "ended":
    case "endedAfterExtraTime":
    case "endedAfterPenalties":
      return "ended";

    case "postponed":
      return "rescheduled";

    case "cancelled":
      return "cancelled";

    case "abandoned":
      return "abandoned";

    case null:
      return "ended";

    default:
      return status;
  }
}

function getMatchingCode(parameterAppString: string): ParameterCode | string | undefined {
  const { findGoalEvent, findMissedPenalty } = matchService();
  let eventCode;
  if (parameterAppString.includes("GOL-ALL")) {
    eventCode = findGoalEvent(parameterAppString);
  } else if (parameterAppString.includes("RIG-F")) {
    eventCode = findMissedPenalty(parameterAppString);
  } else {
    eventCode = [...$MATCH_TIMELINE_EVENT_PARAMS, ...$MATCH_DICE_PARAMS].find(code =>
      parameterAppString.includes(code)
    );
  }

  return eventCode;
}

function getMatchingCodeImage(parameterAppString: string): ParameterCode | string | undefined {
  const { findGoalEvent, findMissedPenalty, findMissedShootoutImage } = matchService();
  const { webContext } = useWebContext();
  let eventCode;

  if (parameterAppString.includes("GOL-ALL")) {
    eventCode = findGoalEvent(parameterAppString, true);
  } else if (parameterAppString.includes("RIG-F")) {
    eventCode = findMissedPenalty(parameterAppString);
  } else if (parameterAppString.includes("SHO-F")) {
    eventCode = findMissedShootoutImage(parameterAppString);
  } else if (parameterAppString.includes("DICE-1GK")) {
    return webContext === "queens" ? "DICE-1GK-ACT-QL" : "DICE-1GK-ACT-KL";
  } else {
    eventCode = [...$MATCH_TIMELINE_EVENT_PARAMS, ...$MATCH_DICE_PARAMS].find(code =>
      parameterAppString.includes(code)
    );
  }

  return eventCode;
}
