// src/region/domain/service/regionUiService.ts
import type { Region } from "~/src/region/domain/models/Region";
import type { RegionDTO } from "~/src/region/infrastructure/RegionDTO";
import regionsData from "~/src/region/datasources/regions.json";
import { useWebContext } from "~/nuxt-ui/Shared/composables/webContext";

interface RegionUiService {
  getRegions: () => Promise<Region[]>;
  getWebContextRegions: () => Promise<Region[]>;
  getCurrentRegion: () => Promise<Region | undefined>;
}

export const $REGION_ROUTE_QUERY_NAME = "region";

const regionUiService = (): RegionUiService => ({
  getRegions,
  getWebContextRegions,
  getCurrentRegion,
});

export default regionUiService;

async function getRegions(): Promise<Region[]> {
  return regionsData as RegionDTO[];
}
async function getWebContextRegions(): Promise<Region[]> {
  const { webContext } = useWebContext();
  const regions = await getRegions();
  const filteredRegions = regions.filter(r => r.webContexts.includes(webContext));

  if (!filteredRegions) throw new Error("Wrong webContext param");

  return filteredRegions;
}

async function getCurrentRegion(): Promise<Region | undefined> {
  const route = useRoute();

  if (!route.params.region) return undefined;

  try {
    const regions = await getWebContextRegions();
    if (regions?.length) {
      return regions.find(r => r.slug === route.params.region);
    } else {
      console.error("Current region not found.");
      return undefined;
    }
  } catch (e) {
    console.error(e);
    return undefined;
  }
}
