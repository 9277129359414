// nuxt-ui/Shared/composables/season-cluster/seasonClusters.ts

import type { SeasonCluster } from "~/src/season-cluster/domain/models/00.SeasonCluster";
import type { Gender } from "~/src/Shared/Types";

interface UseSeasonClusters {
  getSeasonClusters: (genders: Gender[], region: number) => Promise<SeasonCluster[] | undefined>;
}

export const useSeasonClusters = (): UseSeasonClusters => {
  return {
    getSeasonClusters,
  };
};

async function getSeasonClusters(genders: Gender[], countryId: number): Promise<SeasonCluster[] | undefined> {
  try {
    const path = "/api/v1/competition/season-clusters";
    const opts = { query: { genders: genders, region: countryId } };
    const seasonClusters = await $fetch<SeasonCluster[]>(path, opts);

    if (!seasonClusters) {
      console.error("Fetched seasons is null");
    } else {
      return seasonClusters;
    }
  } catch (e) {
    console.error("Could not fetch seasons:", e);
  }
}
