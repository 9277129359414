// nuxt-ui/Shared/composables/seasons.ts

import type {
  Season,
  SeasonTurns,
  StandingPlayoffColors,
} from "~/src/season-cluster/domain/models/01.Season";
import type { SeasonDTO } from "~/src/season-cluster/infrastructure/DTO/01.SeasonDTO";
import type { Group } from "~/src/season-cluster/domain/models/03.Group";
import type { Match } from "~/src/match/domain/models/Match";
import type { SeasonCluster } from "~/src/season-cluster/domain/models/00.SeasonCluster";
import type { Team } from "~/src/team/domain/models/Team";
import type { Phase } from "~/src/season-cluster/domain/models/02.Phase";
import type { Player, RankingsData } from "~/src/player/domain/Player";
import type { Turn } from "~/src/season-cluster/domain/models/04.Turn";
import type { RankingsDataDTO } from "~/src/player/infrastrructure/DTO/PlayerDTO";
import type { NitroFetchOptions } from "nitropack";
import type { UseFetchParams } from "~/src/Shared/Types";
import type { UseFetchOptions } from "#app";
import { useWebContext } from "~/nuxt-ui/Shared/composables/webContext";
import { useCompetitionStore } from "~/nuxt-ui/stores/competitionStore";
import { useTeams } from "~/nuxt-ui/Shared/composables/teams";
import seasonService from "~/src/season-cluster/domain/services/seasonService";
import matchService from "~/src/match/domain/services/matchService";

type SeasonStandingData = {
  positionLegend?: StandingPlayoffColors[] | undefined;
  secondaryPositionLegend?: StandingPlayoffColors[] | undefined;
  phase: Phase | undefined;
};

interface UseSeason {
  getSeason: (seasonId: number) => Promise<Season | undefined>;
  getSeasonTeams: (seasonId: number, options?: getSeasonTeamsOptions) => Promise<Team[]>;
  getSeasonTurns: (seasonId: number) => Promise<{ [key: string]: Match[] }>;
  getSeasonStanding: (seasonId: number) => Promise<SeasonStandingData>;
  getSeasonPlayers: (seasonId: number) => Promise<Player[] | undefined>;
  getSeasonVariablePlayers: (seasonId: number) => Promise<Player[] | undefined>;
  getSeasonTurnPlayers: (seasonId: number) => Promise<Turn[] | undefined>;
  getSelectedSeasonClusterSeasons(seasonCluster: SeasonCluster, regionId: number): Season[];
  findCurrentSeasonClusterSeasons: (seasonCluster: SeasonCluster) => Season[];
  getSeasonParameterStats: (
    seasonId: number,
    parameterCode: string,
    sorted?: boolean,
    orderBy?: string,
    sortOrder?: string
  ) => Promise<RankingsData[] | undefined>;
  useFetchParamsSeasonStatsFirstPlayer: (
    seasonId: number,
    parameterCode: string,
    sorted?: boolean,
    orderBy?: "ranking" | "rankingAVG",
    sortOrder?: "ASC" | "DESC"
  ) => UseFetchParams;
  useFetchParamsSeasonStatsFirstGoalkeeper: (seasonId: number) => UseFetchParams;
  useFetchParamsSeasonStatsFirstGoalkeeperKWC: (seasonId: number) => UseFetchParams;
  useFetchParamsSeasonStatsFirstCards: (seasonId: number) => UseFetchParams;
  useFetchParamsSeasonStatsFirstTeam: (
    seasonId: number,
    parameterCode: string,
    sorted?: boolean,
    orderBy?: string,
    sortOrder?: string
  ) => UseFetchParams;
  useFetchParamsSeasonMatchCenterData: (seasonId: number, locale: string, teamId?: number) => UseFetchParams;
}

export const useSeasons = (): UseSeason => {
  return {
    getSeason,
    getSeasonTeams,
    getSeasonTurns,
    getSeasonStanding,
    getSeasonPlayers,
    getSelectedSeasonClusterSeasons,
    findCurrentSeasonClusterSeasons: getCurrentSeasonClusterSeasons,
    getSeasonVariablePlayers,
    getSeasonTurnPlayers,
    getSeasonParameterStats,
    useFetchParamsSeasonStatsFirstPlayer,
    useFetchParamsSeasonStatsFirstGoalkeeper,
    useFetchParamsSeasonStatsFirstGoalkeeperKWC,
    useFetchParamsSeasonStatsFirstCards,
    useFetchParamsSeasonStatsFirstTeam,
    useFetchParamsSeasonMatchCenterData,
  };
};

async function getSeason(seasonId: number): Promise<Season | undefined> {
  const path = `/api/v1/competition/seasons/${seasonId}`;
  const { currentSeason } = useCompetitionStore();
  const { normalizeSeason } = seasonService();
  const { value: locale } = useCookie("lang");
  const opts: NitroFetchOptions<any> = { query: { lang: locale || "es" } };

  try {
    const season = await $fetch<SeasonDTO>(path, opts);

    if (!season) {
      console.error("season data is null or undefined");
    } else {
      return normalizeSeason(season);
    }
  } catch (e) {
    console.error("Error fetching season data: ", e);
    return currentSeason;
  }
}

type getSeasonTeamsOptions = {
  withStaff?: boolean;
};

const baseGetSeasonTeamsOptions: getSeasonTeamsOptions = {
  withStaff: false,
};

async function getSeasonTeams(seasonId: number, options?: getSeasonTeamsOptions): Promise<Team[]> {
  const { getTeam, getSeasonTeamStaff } = useTeams();
  const opts = { ...baseGetSeasonTeamsOptions, ...(options || {}) };

  try {
    let path = `/api/v1/competition/seasons/${seasonId}/teams`;
    const season = await $fetch<SeasonDTO>(path);
    const { teams } = season;

    if (!!teams) {
      if (opts.withStaff) {
        for (const team of teams) {
          if (!team.staffs) team.staffs = await getSeasonTeamStaff(seasonId, team.id);
        }
      }

      const placeholderTeamIds = [58, 63, 64, 65, 66];
      const placeholderTeams = [];

      for (const pt of placeholderTeamIds) {
        const placeholderTeam = await getTeam(pt);
        if (placeholderTeam) {
          placeholderTeam.isPlaceholder = true;
          placeholderTeams.push(placeholderTeam);
        }
      }

      if (placeholderTeams.length) {
        return [...teams, ...placeholderTeams];
      } else {
        return teams;
      }
    } else {
      console.error("Fetched season teams are null or undefined");
      return [];
    }
  } catch (e) {
    console.error("Error fetching season teams:", e);
    return [];
  }
}

async function getSeasonTurns(seasonId: number): Promise<{ [key: string]: Match[] }> {
  const { orderMatchesByDate } = matchService();
  const seasonData: Season | undefined = await getSeason(seasonId);

  //ADD redemption season as a group inside world-cup season phases
  if (seasonData && seasonData.id === 13) {
    const redemptionKwc: Season | undefined = await getSeason(14);
    const redemptionKwcGroups = redemptionKwc?.phases;
    if (redemptionKwcGroups && redemptionKwcGroups.length) seasonData.phases.unshift(...redemptionKwcGroups);
  }

  const groups: Group[] | undefined = seasonData
    ? seasonData?.phases.map(phase => phase.groups).flat()
    : undefined;

  return (
    groups?.reduce(
      (result, category) => {
        const orderedTurns = category.turns.sort((a, b) => {
          return a.start && b.start && a.start > b.start
            ? 1
            : a.start && b.start && a.start < b.start
              ? -1
              : 0;
        });

        orderedTurns.forEach(turn => {
          const turnName = turn.name;
          const _groupName = category.name;

          for (const match of turn.matches) {
            match.groupName = _groupName;
          }

          if (turn.matches.length) {
            if (!result[turnName]) result[turnName] = [];
            const orderedMatches = orderMatchesByDate(turn.matches);

            result[turnName] = [...result[turnName], ...orderedMatches];
          }
        });

        return result;
      },
      {} as { [key: string]: Match[] }
    ) || ({} as { [key: string]: Match[] })
  );
}

async function getSeasonStanding(seasonId: number): Promise<SeasonStandingData> {
  if (!seasonId) console.error("seasonId is not defined");
  const { findSeasonStandingPhase } = seasonService();

  const seasonData: Season | undefined = await getSeason(seasonId);
  if (seasonData) {
    const { metaInformation: competitionMeta } = seasonData;

    return {
      positionLegend: competitionMeta?.position_legend,
      secondaryPositionLegend: competitionMeta?.secondary_position_legend,
      phase: findSeasonStandingPhase(seasonData),
    };
  } else {
    return {
      positionLegend: undefined,
      secondaryPositionLegend: undefined,
      phase: undefined,
    };
  }
}

function getSelectedSeasonClusterSeasons(seasonCluster: SeasonCluster, regionId: number): Season[] {
  if (!seasonCluster) {
    console.error("seasonCluster not defined");
    return [];
  }

  const { webContextGenders } = useWebContext();

  return (
    seasonCluster.seasons
      .filter(season => {
        const {
          competition: { gender: seasonGender },
        } = season;
        const contextGenderSeason = webContextGenders.includes(seasonGender);
        const currentRegionSeason = regionId === season.competition.countryId;
        return currentRegionSeason && contextGenderSeason;
      })
      .filter(s => s.competition.visible)
      .sort((a, b) => {
        return a.start > b.start ? 1 : a.start < b.start ? -1 : 0;
      }) || []
  );
}

function getCurrentSeasonClusterSeasons(seasonCluster: SeasonCluster): Season[] {
  if (!seasonCluster) {
    console.error("seasonCluster not defined");
    return [];
  }

  const { region: currentRegion } = useCompetitionStore();
  const countryId = currentRegion?.apiId;
  const { webContextGenders } = useWebContext();

  return (
    seasonCluster.seasons
      .filter(season => {
        const {
          competition: { gender: seasonGender },
        } = season;
        const contextGenderSeason = webContextGenders.includes(seasonGender);
        const currentRegionSeason = countryId === season.competition.countryId;
        return currentRegionSeason && contextGenderSeason;
      })
      .sort((a, b) => {
        return a.start > b.start ? 1 : a.start < b.start ? -1 : 0;
      }) || []
  );
}

async function getSeasonTurnPlayers(seasonId: number): Promise<Turn[] | undefined> {
  try {
    const path = `/api/v1/competition/seasons/${seasonId}/players/turn-players`;
    const { value: locale } = useCookie("lang");
    const opts: NitroFetchOptions<any> = { query: { lang: locale || "es" } };

    const season = await $fetch<SeasonTurns>(path, opts);

    if (season) {
      return season.turns;
    } else {
      console.error("Fetched turn players are undefined or null");
    }
  } catch (e) {
    console.error("Error fetching Season Turn Players Data:", e);
    return undefined;
  }
}

async function getSeasonPlayers(seasonId: number): Promise<Player[] | undefined> {
  try {
    const path = `/api/v1/competition/seasons/${seasonId}/players`;
    const { webContextGenders } = useWebContext();
    const { value: locale } = useCookie("lang");
    const opts = { query: { lang: locale, ctxGenders: webContextGenders, categories: ["draft"] } };

    return await $fetch<Player[]>(path, opts);
  } catch (e) {
    console.error("Error fetching season players:", e);
    return undefined;
  }
}

async function getSeasonVariablePlayers(seasonId: number): Promise<Player[] | undefined> {
  try {
    const path = `/api/v1/competition/seasons/${seasonId}/players`;
    const { webContextGenders } = useWebContext();
    const { value: locale } = useCookie("lang");
    const opts = {
      query: { lang: locale, ctxGenders: webContextGenders, categories: ["wildcard"] },
    };

    return await $fetch<Player[]>(path, opts);
  } catch (e) {
    console.error("Error fetching season variable players:", e);
    return undefined;
  }
}

async function getSeasonParameterStats(
  seasonId: number,
  parameterCode: string,
  sorted?: boolean,
  orderBy?: string,
  sortOrder?: string
): Promise<RankingsData[] | undefined> {
  try {
    let path, response;
    if (sorted) {
      path = `/api/v1/competition/seasons/${seasonId}/players/stats/${parameterCode}?orderBy=${orderBy}&sortOrder=${sortOrder}`;
    } else {
      path = `/api/v1/competition/seasons/${seasonId}/players/stats/${parameterCode}`;
    }
    const players = await $fetch<RankingsDataDTO[]>(path);

    const { webContextGenders } = useWebContext();
    if (players) {
      response = players.filter(p => {
        const playerGender = p?.player?.gender;
        return playerGender ? webContextGenders.includes(playerGender) : false;
      });
    }
    if (response) {
      return response;
    } else {
      console.error("Fetched season stats is null:", parameterCode);
    }
  } catch (e) {
    console.error("Error on fetch stats", e);
  }
}

function useFetchParamsSeasonStatsFirstPlayer(
  seasonId: number,
  parameterCode: string,
  sorted?: boolean,
  orderBy?: string,
  sortOrder?: string
): UseFetchParams {
  let path;
  if (sorted) {
    path = `/api/v1/competition/seasons/${seasonId}/players/stats/${parameterCode}?orderBy=${orderBy}&sortOrder=${sortOrder}`;
  } else {
    path = `/api/v1/competition/seasons/${seasonId}/players/stats/${parameterCode}`;
  }

  const opts: UseFetchOptions<any> = {
    query: { page: 0 },
    transform: input => {
      if ((input && parameterCode === "GOL-S") || (input && parameterCode === "PRT")) {
        const goalkeepers = input.filter((r: RankingsData) => r.player.role === "goalkeeper");
        return goalkeepers[0];
      } else {
        return input[0];
      }
    },
  };

  return { path, opts };
}

function useFetchParamsSeasonStatsFirstGoalkeeper(seasonId: number): UseFetchParams {
  const path = `/api/v1/competition/seasons/${seasonId}/players/stats/GOL-S`;

  const opts: UseFetchOptions<any> = {
    query: {
      page: 0,
      pageSize: 50,
      orderBy: "rankingAVG",
      sortOrder: "DESC",
    },
    transform: input => {
      if (input) {
        const goalkeepers = input.filter((r: RankingsData) => r.player.role === "goalkeeper");
        //const gkWithFiveMatches = goalkeepers.filter((p: RankingsData) => (p.matchesPlayed || 0) > 4);
        // const bestGkEligible = gkWithFiveMatches.filter((p: RankingsData) =>
        //   [2, 3, 8, 49].includes(p.team.id)
        // );
        return { ...goalkeepers[0], totalAVG: goalkeepers[0].totalAVG.toFixed(2) };
      }
    },
  };

  return { path, opts };
}

function useFetchParamsSeasonStatsFirstGoalkeeperKWC(seasonId: number): UseFetchParams {
  const path = `/api/v1/competition/seasons/${seasonId}/players/stats/GOL-S`;

  const opts: UseFetchOptions<any> = {
    query: {
      page: 0,
      pageSize: 50,
      orderBy: "rankingAVG",
      sortOrder: "DESC",
    },
    transform: input => {
      if (input) {
        const goalkeepers = input.filter((r: RankingsData) => r.player.role === "goalkeeper");
        const gkWithFiveMatches = goalkeepers.filter((p: RankingsData) => (p.matchesPlayed || 0) > 4);
        const bestGkEligible = gkWithFiveMatches.filter((p: RankingsData) =>
          [2, 3, 8, 49].includes(p.team.id)
        );
        return { ...bestGkEligible[0], totalAVG: bestGkEligible[0].totalAVG.toFixed(2) };
      }
    },
  };

  return { path, opts };
}

function useFetchParamsSeasonStatsFirstCards(seasonId: number): UseFetchParams {
  const { webContextGenders } = useWebContext();
  const path = "/api/stats";

  const opts: UseFetchOptions<any> = {
    query: {
      ctxGenders: webContextGenders,
      season: seasonId,
      type: "players",
      mainCode: "CRT-G",
      secondaryCodes: "CRT-R",
      orderBy: "ranking",
      sortOrder: "ASC",
    },
    transform: input => {
      return input[0];
    },
  };

  return { path, opts };
}

function useFetchParamsSeasonStatsFirstTeam(
  seasonId: number,
  parameterCode: string,
  sorted?: boolean,
  orderBy?: string,
  sortOrder?: string
): UseFetchParams {
  let path;
  if (sorted) {
    path = `/api/v1/competition/seasons/${seasonId}/teams/stats/${parameterCode}?orderBy=${orderBy}&sortOrder=${sortOrder}`;
  } else {
    path = `/api/v1/competition/seasons/${seasonId}/teams/stats/${parameterCode}`;
  }

  const opts: UseFetchOptions<any> = {
    transform: input => {
      return input[0];
    },
  };

  return { path, opts };
}

function useFetchParamsSeasonMatchCenterData(
  seasonId: number,
  locale: string,
  teamId?: number
): UseFetchParams {
  const path = `/api/v1/competition/seasons/${seasonId}/match-center-data`;
  const opts: NitroFetchOptions<any> = { query: { lang: locale || "es", teamId } };

  return { path, opts };
}
